import React from 'react'

import cookies from 'js-cookie'

import fcb from "../../assets/img/Team/Section/fcb.svg"
import lin from "../../assets/img/Team/Section/in.svg"
import tw from "../../assets/img/Team/Section/tw.svg"
import ig from "../../assets/img/Team/Section/ig.svg"
import yt from "../../assets/img/Team/Section/yt.svg"

function TeamMemberCard({ team }) {
     return (

          <div className='teamMemberCard'>

               <div className="profile">
                    <img className='profile_img' src={team.photo} alt='' />

                    <div className="sm">
                         {team.facebook === null ? null : <a href={team.facebook} target='_blank'><img src={fcb} alt="" /></a>}
                         {team.instagram === null ? null : <a href={team.instagram} target='_blank'><img src={ig} alt="" /></a>}
                         {team.linkedin === null ? null : <a href={team.linkedin} target='_blank'><img src={lin} alt="" /></a>}
                         {team.twitter === null ? null : <a href={team.twitter} target='_blank'><img src={tw} alt="" /></a>}
                         {team.youtube === null ? null : <a href={team.youtube} target='_blank'><img src={yt} alt="" /></a>}
                    </div>
               </div>

               <p className="name">{team.full_name}</p>
               <p className="position">{cookies.get('i18next') === 'ar' ? team.workplace__ar : (cookies.get('i18next') === 'fr' ? team.workplace__fr : team.workplace__en)}  </p>

          </div>

     )

}
export default TeamMemberCard